* {
  font-family: 'Roboto condensed', Helvetica, Arial, sans-serif;
  color: #404041;
}

header {
  border-bottom: 1px solid #ff8200;
  z-index: 9999;
  position: relative;
  background-color: #fff;
}

figure img {
  vertical-align: middle;
  width: 100%;
}

a,
a:hover,
a:visited,
a:focus {
  text-decoration: none;
  color: #404041;
}

.link-classic,
.link-classic:hover,
.link-classic:visited,
.link-classic:focus {
  text-decoration: underline;
}

.fa > span {
  display: none;
}

.bg-anthracite {
  background-color: #404041;
}

.bg-white {
  background-color: #fff;
}

.bg-light-grey {
  background-color: #f6f6fe;
}

.bg-gradient-orange {
  background: #ffa735;
  background: -moz-linear-gradient(left, #ffa735 0%, #fd613d 100%);
  background: -webkit-linear-gradient(left, #ffa735 0%, #fd613d 100%);
  background: linear-gradient(to right, #ffa735 0%, #fd613d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffa735', endColorstr='#fd613d',GradientType=1 );
}

.new-btn,
a.new-btn {
  display: inline-block;
  border-radius: 5px;
  text-transform: uppercase;
  border: 2px solid transparent;
  padding: 8px 15px;
  transition: all 0.3s ease;
  cursor: pointer;
  vertical-align: middle;
}

.btn-classic,
a.btn-classic {
  cursor: pointer;
  color: inherit;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 5px;
  padding: 8px 15px;
  font-weight: 700;
  vertical-align: middle;
  overflow: hidden;
  transition: all 0.3s ease;
}

.btn-grey,
a.btn-grey {
  color: #404041;
  border-color: #404041;
}

.btn-grey:hover,
a.btn-grey:hover {
  color: #fff;
  background-color: #404041;
}

.btn-wide,
a.btn-wide {
  padding: 8px 25px;
}

.height-350 {
  min-height: 350px;
}

.height-350 figure {
  width: 100%;
  height: 100%;
}

.height-350 figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.height-350 figure img.contain {
  object-fit: contain;
}

.block-video-yt iframe {
  min-height: 400px;
  width: 100%;
}

.block-video-yt article figure {
  padding: 15px 20px;
  border-radius: 5px;
  background: #fff;
  overflow: hidden;
}
.block-video-yt article figure img {
  width: 100%;
}

.block-video-yt article figure.max-height-img {
  background: transparent;
}
.block-video-yt article figure.max-height-img img {
  width: auto;
  max-height: 150px;
  margin: 0 auto 20px;
}
.block-video-yt article figure.max-height-img + div p {
  font-size: 1.8em;
}

.list-classic {
  margin-left: 15px;
}

.force-left {
  right: 50%;
}
.force-right {
  left: 50%;
}

@media only screen and (max-width: 768px) {
  h5 {
    font-size: 1rem;
  }

  h1 {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 992px) {
  .force-left {
    right: 0%;
  }
  .force-right {
    left: 0%;
  }
}
