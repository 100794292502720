.Pictogram {
  cursor: pointer;
}

.Pictogram a > div {
  height: 200px;
  padding: 15px;
}

.Pictogram a:hover {
  text-decoration: none;
}

.Pictogram img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.Pictogram a > h2 {
  font-weight: 700;
}
