.media-arrow::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  border-style: solid;
  z-index: 99;
}

.media-arrow-left::before {
  border-width: 30px 45px 30px 0;
  border-color: transparent #f6f6fe transparent transparent;
  left: auto;
  right: 0;
}

.media-arrow-right::before {
  border-width: 30px 0 30px 45px;
  border-color: transparent transparent transparent #f6f6fe;
  left: 0;
  right: auto;
}

.notice-small-img {
  padding: 120px 0;
}
.notice-small-img img {
  width: 100%;
  max-width: 450px;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .notice-small-img {
    padding: 10px 0;
  }
  .notice-small-img img {
    max-width: none;
  }
}

@media only screen and (max-width: 992px) {
  .media-arrow::before {
    border-width: 0 30px 45px 30px;
    border-color: transparent transparent #f6f6fe transparent;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    transform: none;
  }
}
