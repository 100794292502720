.zone:hover {
  box-shadow: none;
  border: 1px solid rgba(255, 130, 0, 1);
}

.Interface-description {
  position: fixed;
  max-width: 50%;
  background-color: white;
  border: 1px solid #404041;
  border-radius: 5px;
  padding: 15px 10px 15px 10px !important;
  text-align: left;
}

.container-interface img,
.container-interface picture {
  width: 100%;
}

.container-interface .active {
  background-color: #333333;
  color: #fff;
}

.zone {
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  height: auto;
  position: absolute;
  padding-top: 4%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
  width: 5%;
}

.zone.zone-medium {
  width: 10%;
  padding-top: 9%;
}
.zone.zone-big {
  width: 15%;
  padding-top: 14%;
}

.zone:hover {
  cursor: pointer;
}
