@keyframes enlarge-zone-click {
  0% {
    transform: scale(0.5, 0.5);
  }
  10% {
    transform: scale(0.5, 0.5);
  }
  30% {
    transform: scale(1, 1);
  }
  60% {
    transform: scale(0.5, 0.5);
  }
  100% {
    transform: scale(0.5, 0.5);
  }
}

@keyframes moving-cursor {
  0% {
    transform: translateY(18px);
  }
  10% {
    transform: translateY(18px);
  }
  30% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(18px);
  }
  100% {
    transform: translateY(18px);
  }
}

@media only screen and (max-width: 576px) {
  @keyframes moving-cursor {
    0% {
      transform: translateY(7px);
    }
    20% {
      transform: translateY(7px);
    }
    30% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(7px);
    }
    100% {
      transform: translateY(7px);
    }
  }

  @keyframes enlarge-zone-click {
    0% {
      transform: scale(0.6, 0.6);
    }
    19% {
      transform: scale(0.6, 0.6);
    }
    30% {
      transform: scale(1, 1);
    }
    40% {
      transform: scale(0.6, 0.6);
    }
    100% {
      transform: scale(0.6, 0.6);
    }
  }
}

.fa-hand-pointer-o {
  cursor: pointer;
}

.fa-hand-pointer-o span {
  display: none;
}

.moving-banner-top {
  min-height: 500px;
}

.moving-banner-top article.covered figure figcaption {
  display: none;
}

.moving-banner-top figure figcaption.valid {
  right: 5%;
  left: auto;
}

.moving-banner-top figure figcaption.error {
  left: 5%;
  right: auto;
}

.moving-banner-top article.covered {
  width: 80%;
}

.moving-banner-top {
  width: 100%;
  position: relative;
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}
.moving-banner-top article {
  width: 50%;
  height: 100%;
  display: inline-block;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  transition: width 0.5s cubic-bezier(0.22, 0.89, 0.44, 0.99),
    z-index 0.1s cubic-bezier(0.22, 0.89, 0.44, 0.99);
  transition-delay: 0.2s, 0.3s;
}

.moving-banner-top article:nth-of-type(1) {
  left: 0;
}
.moving-banner-top article:nth-of-type(2) {
  right: 0;
}

.moving-banner-top figure {
  width: 100%;
  height: 100%;
  position: relative;
}
.moving-banner-top article:nth-of-type(2) figure {
  position: absolute;
  right: 0;
}

.moving-banner-top figure img {
  width: 100%;
  max-height: 500px;
}

.moving-banner-top figure figcaption {
  position: absolute;
  top: 10%;
  bottom: auto;
  margin: auto;
  width: 40%;
  text-align: center;
  padding: 20px;
  height: auto;
  background-color: rgba(255, 255, 255, 0.6);
  border-left: 10px solid #404041;
  transform: translateX(50%);
  transition: background-color 0.3s ease, transform 0.3s ease;
  transition-delay: 0s, 0.2s;
}
.moving-banner-top figure figcaption.error {
  border-color: #bc251e;
}
.moving-banner-top figure figcaption.valid {
  border-color: #19bc3b;
}

.moving-banner-top article:nth-of-type(2) figure figcaption {
  transform: translateX(-50%);
}
.moving-banner-top article:hover figure figcaption {
  transform: translateX(0);
  transition-delay: 0.3s;
}
.moving-banner-top article.covered figure figcaption {
  transform: translateX(50%);
  transition-delay: 0.3s;
}

.moving-banner-top figure figcaption a {
  text-decoration: none;
  color: inherit;
  border: 2px solid #404041;
  text-transform: uppercase;
  font-weight: 700;
  padding: 5px 20px;
  border-radius: 5px;
  margin: 10px auto;
  display: inline-block;
  font-size: 2.2em;
  text-align: left;
  overflow: hidden;
  position: absolute;
  bottom: 20px;
}

.moving-banner-top figure figcaption a:after {
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  background-color: #404041;
  position: absolute;
  left: 100%;
  top: -2px;
  padding: 5px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  z-index: 1;
}
.moving-banner-top figure figcaption a:hover:after {
  left: 0;
}
.moving-banner-top figure figcaption a:hover {
  color: #fff;
}

.moving-banner-top article .hover-tap {
  top: 0;
  position: absolute;
  width: 25%;
  background-color: rgba(87, 88, 90, 0.8);
  height: 100%;
  z-index: 0;
  transition: all 0.3s ease;
  opacity: 0;
}

.moving-banner-top figure figcaption,
.moving-banner-top article:nth-of-type(2) figure figcaption {
  transform: translateX(0);
}
.moving-banner-top figure figcaption a {
  bottom: 20px;
}
.moving-banner-top article.hover {
  width: 80%;
  z-index: 2;
  transition: width 0.5s cubic-bezier(0.22, 0.89, 0.44, 0.99),
    z-index 0s cubic-bezier(0.22, 0.89, 0.44, 0.99);
  transition-delay: 0.2s, 0.2s;
  box-shadow: 10px 0 3px rgba(0, 0, 0, 0.5);
}
.moving-banner-top article:nth-of-type(2).hover {
  box-shadow: -10px 0 3px rgba(0, 0, 0, 0.5);
}
.moving-banner-top article.covered .hover-tap {
  opacity: 1;
  transition-delay: 0.6s;
}
.moving-banner-top article.covered .hover-tap i {
  font-size: 60px;
  color: #fff;
  position: relative;
  top: 50%;
}
.moving-banner-top article.covered .hover-tap i:before {
  display: inline-block;
  animation: moving-cursor 4s infinite;
  animation-fill-mode: both;
  transition: all 0.3s ease;
  transform: translateY(18px);
}

.moving-banner-top article.covered .hover-tap i:after {
  content: '';
  position: absolute;
  top: -20px;
  left: -2px;
  width: 40px;
  height: 40px;
  border-top: 4px solid #fff;
  border-right: 4px solid #fff;
  border-left: 4px solid #fff;
  border-bottom: 4px solid rgba(87, 88, 90, 0);
  z-index: -1;
  border-radius: 50%;
  display: inline-block;
  animation: enlarge-zone-click 4s infinite;
  animation-fill-mode: both;
  transition: all 0.3s ease;
  opacity: 0.75;
  transform: scale(0.5, 0.5);
}
.moving-banner-top article:nth-of-type(2).covered .hover-tap {
  right: 0;
}

.moving-banner-top article.covered .hover-tap {
  opacity: 1;
  transition-delay: 0.6s;
}
.moving-banner-top article.covered .hover-tap i {
  font-size: 60px;
  color: #fff;
  position: relative;
  top: 50%;
}
.moving-banner-top article.covered .hover-tap i:before {
  display: inline-block;
  animation: moving-cursor 1s infinite;
  animation-fill-mode: both;
  transition: all 0.3s ease;
  transform: translateY(18px);
}

.moving-banner-top article.covered .hover-tap i:after {
  content: '';
  position: absolute;
  top: -20px;
  left: -2px;
  width: 40px;
  height: 40px;
  border-top: 4px solid #fff;
  border-right: 4px solid #fff;
  border-left: 4px solid #fff;
  border-bottom: 4px solid rgba(87, 88, 90, 0);
  z-index: -1;
  border-radius: 50%;
  display: inline-block;
  animation: enlarge-zone-click 1s infinite;
  animation-fill-mode: both;
  transition: all 0.3s ease;
  opacity: 0.75;
  transform: scale(0.5, 0.5);
}
.moving-banner-top article:nth-of-type(2).covered .hover-tap {
  right: 0;
}

@media only screen and (max-width: 992px) {
  .moving-banner-top figure img {
    height: 50%;
  }

  .moving-banner-top figure figcaption {
    width: 50%;
  }
  .moving-banner-top figure figcaption,
  .moving-banner-top article:nth-of-type(2) figure figcaption {
    transform: translateX(0);
  }
  .moving-banner-top figure figcaption a {
    bottom: 20px;
  }
  .moving-banner-top article.hover {
    width: 80%;
    z-index: 2;
    transition: width 0.5s cubic-bezier(0.22, 0.89, 0.44, 0.99),
      z-index 0s cubic-bezier(0.22, 0.89, 0.44, 0.99);
    transition-delay: 0.2s, 0.2s;
    box-shadow: 10px 0 3px rgba(0, 0, 0, 0.5);
  }
  .moving-banner-top article:nth-of-type(2).hover {
    box-shadow: -10px 0 3px rgba(0, 0, 0, 0.5);
  }
  .moving-banner-top article.covered .hover-tap {
    opacity: 1;
    transition-delay: 0.6s;
  }
  .moving-banner-top article.covered .hover-tap i {
    font-size: 60px;
    color: #fff;
    position: relative;
    top: 50%;
  }
  .moving-banner-top article.covered .hover-tap i:before {
    display: inline-block;
    animation: moving-cursor 4s infinite;
    animation-fill-mode: both;
    transition: all 0.3s ease;
    transform: translateY(18px);
  }

  .moving-banner-top article.covered .hover-tap i:after {
    content: '';
    position: absolute;
    top: -20px;
    left: -2px;
    width: 40px;
    height: 40px;
    border-top: 4px solid #fff;
    border-right: 4px solid #fff;
    border-left: 4px solid #fff;
    border-bottom: 4px solid rgba(87, 88, 90, 0);
    z-index: -1;
    border-radius: 50%;
    display: inline-block;
    animation: enlarge-zone-click 4s infinite;
    animation-fill-mode: both;
    transition: all 0.3s ease;
    opacity: 0.75;
    transform: scale(0.5, 0.5);
  }
  .moving-banner-top article:nth-of-type(2).covered .hover-tap {
    right: 0;
  }
}

@media only screen and (max-width: 992px) {
  .moving-banner-top figure figcaption {
    width: 75%;
    background-color: rgba(255, 255, 255, 0.4);
    top: 250px;
  }
}

@media only screen and (max-width: 768px) {
  .moving-banner-top figure figcaption {
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .moving-banner-top figure figcaption a {
    bottom: 5px;
    font-size: 1.8em;
    padding: 2px 5px;
  }
}

@media only screen and (max-width: 576px) {
  .moving-banner-top article.covered .hover-tap i {
    font-size: 40px;
  }
  .moving-banner-top article.covered .hover-tap i:after {
    top: -15px;
    left: -1px;
    width: 30px;
    height: 30px;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    border-left: 3px solid #fff;
    border-bottom: 3px solid rgba(87, 88, 90, 0);
  }
}

@media only screen and (max-width: 576px) {
  .moving-banner-top figure figcaption {
    width: 85%;
  }

  .moving-banner-top figure figcaption a {
    font-size: 1.2em;
    bottom: 0;
  }
}
